.featherlight .featherlight-content {
	border-bottom: none !important;
}

.featherlight .featherlight-content .invex-popup > .container {
	display: flex;
}

.featherlight .featherlight-content > .featherlight-close {
	display: none;
}

.invex-popup {
	display: none;
	background: white;

	> .emergency-toggle {
		position: fixed;
		top: 10px;
		left: 0;
		right: 0;
		z-index: 1;

		display: none;
		justify-content: center;

		&.enabled {
			display: flex;
		}

		.button {
			padding: 5px 10px;
			color: white;
			background-color: rgba(220, 50, 50, 1);
			cursor: pointer;
			@include transition();

			&:hover {
				background-color: rgba(220, 50, 50, 0.7);
			}
		}
	}

	> .text-background {
		display: none;
	}

	> .container {
		display: flex;
		max-width: 85vw;
		max-height: 95vh;
		position: relative;

		@include respond-below(popup) {
			flex-direction: column;
			width: 300px !important;
			height: auto !important;
		}

		&.no-image {
			width: 500px;
		}

		&.has-image {
			width: 750px;
			height: 450px;
		}

		&.background-size--contain {
			> .image {
				background-size: contain !important;
			}
		}
		&.background-size--cover {
			> .image {
				background-size: cover !important;
			}
		}

		> .image {
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			position: relative;
			z-index: 1;
		}

		> .image-link {
			opacity: 1;
			@include transition();

			&:hover {
				opacity: 0.85;
			}
		}

		> .text {
			flex: 1;

			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			position: relative;
			z-index: 3;

			> .featherlight-close {
				position: absolute;
				top: 10px;
				right: 10px;
				z-index: 2;
				font-size: 30px;
				font-weight: 700;
				color: #333;
				opacity: 1;
				cursor: pointer;
				@include transition();

				@include respond-below(popup) {
					top: 5px;
					right: 5px;
					font-size: 20px;
				}

				&:hover {
					opacity: 0.7;
				}
			}

			> .container {
				margin: 30px;

				@include respond-below(popup) {
					margin: 20px;
				}
			}

			.heading {
				margin-bottom: 25px;
				font-size: 37px;
				font-weight: 700;
				font-style: normal;
				text-transform: none;
				letter-spacing: 0;
				line-height: 1em;
				text-align: center;
				color: #333333;

				@include respond-below(popup) {
					font-size: 25px;
				}
			}

			.content {
				font-weight: 400;
				font-style: normal;
				font-size: 15px;
				line-height: 1.6em;
				text-transform: none;
				letter-spacing: .015em;
				color: #333333;
				text-align: center;

				@include respond-below(popup) {
					font-size: 14px;
				}

				a {
					color: blue;
				}
			}

			.button-link {
				display: block;
				width: 100%;
				margin-top: 20px;
				padding: 12px;
				text-decoration: none;
				background: #333333;
				color: #fff;
				border-style: none;
				text-align: center;
				opacity: 1;
				transition: 300ms all linear;

				@include respond-below(popup) {
					padding: 8px;
				}

				&:hover {
					opacity: 0.8;
				}
			}
		}
	}

	&.image-layout--background {
		> .container {
			> .image {
				position: absolute !important;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background-size: cover !important;
				z-index: 1;
			}

			> .text-background {
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				opacity: 0.7;
				background-color: white;
				z-index: 2;
			}

			> .text {
				position: relative;
				z-index: 3;
			}
		}
	}

	&.image-layout--left,
	&.image-layout--right {
		> .container {
			.image {
				flex-basis: 50%;
				background-size: contain;

				@include respond-below(popup) {
					display: none;
				}
			}
		}
	}

	&.image-layout--left {
		> .container {
			.image {
				order: 1;
			}
			.text {
				order: 2;
			}
		}
	}

	&.image-layout--right {
		> .container {
			.image {
				order: 2;
			}
			.text {
				order: 1;
			}
		}
	}

	&.type--emergency {
		margin: 0;
		padding: 0;
		border: 5px solid rgba(220,50,50,1.0);
		background: #f0dbda;
		color: white;
	}
}
