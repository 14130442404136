@mixin box-shadow($xOffset: 0, $yOffset: 4px, $blur: 10px, $spread: -2px, $color: #333333) {
	-webkit-box-shadow: $xOffset $yOffset $blur $spread $color;
	-moz-box-shadow: $xOffset $yOffset $blur $spread $color;
	box-shadow: $xOffset $yOffset $blur $spread $color;
}

/* Border Radius Mixin */
@mixin border-radius($radius) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	border-radius: $radius;
}

@mixin transition($element: all, $duration: 300ms, $effect: ease-in-out) {
	-webkit-transition: $element $duration $effect;
	-moz-transition: $element $duration $effect;
	-o-transition: $element $duration $effect;
	transition: $element $duration $effect;
}

/* Placeholder Colors */
@mixin placeholderColor($color) {
	::-webkit-input-placeholder {
		color: $color;
	}
	:-moz-placeholder {
		color: $color;
	}
	::-moz-placeholder {
		color: $color;
	}
	:-ms-input-placeholder {
		color: $color;
	}
}

/* Header Adjustment */
@mixin admin-header-fix($offset: 0) {
	$narrow-offset: 46px;
	$wide-offset: 32px;
	overflow: hidden;

	@if $offset != 0 and type-of($offset) == 'number' {
		$narrow-offset: $narrow-offset + $offset;
		$wide-offset: $wide-offset + $offset;
	}

	.admin-bar & {
		top: $narrow-offset;
		@media screen and (min-width: 783px) {
			top: $wide-offset;
		}
	}
}

@mixin opacity($opacity: 0.5) {
	-webkit-opacity: $opacity;
	-moz-opacity: $opacity;
	opacity: $opacity;
}

@mixin animation($name, $duration: 300ms, $delay: 0, $ease: ease) {
	-webkit-animation: $name $duration $delay $ease;
	-moz-animation: $name $duration $delay $ease;
	-ms-animation: $name $duration $delay $ease;
}

@mixin font($font-family, $font-file) {
	@font-face {
		font-family: $font-family;
		//src: url($font-file + '.eot');
		src: url($font-file + '.ttf') format('truetype');
		//src:
			//url($font-file + '.eot?#iefix') format('embedded-opentype'),
			//url($font-file + '.woff') format('woff'),
			//url($font-file + '.svg#aller') format('svg');
		font-weight: normal;
		font-style: normal;
	}
}
