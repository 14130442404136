// Example of how to call this: @include respond-above(sm) {}
@mixin respond-above($breakpoint) {
	@if map-has-key($breakpoints, $breakpoint) {
		$breakpoint-value: map-get($breakpoints, $breakpoint);

		@media (min-width: $breakpoint-value) { @content;}

	} @else {
		@warn 'Invalid breakpoint: #{$breakpoint}.';
	}
}

@mixin respond-above-custom($breakpoint) {
	@media (min-width: $breakpoint) { @content;}
}

// Example of how to call this: @include respond-below(sm) {}
@mixin respond-below($breakpoint) {
	@if map-has-key($breakpoints, $breakpoint) {
		$breakpoint-value: map-get($breakpoints, $breakpoint);
		@media (max-width: ($breakpoint-value - 1)) { @content; }

	} @else {
		@warn 'Invalid breakpoint: #{$breakpoint}.';
	}
}
@mixin respond-below-custom($breakpoint) {
	@media (max-width: ($breakpoint - 1)) { @content; }
}

// Example of how to call this: @include respond-between(sm, md) {}
@mixin respond-between($lower, $upper) {
	@if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {
		$lower-breakpoint: map-get($breakpoints, $lower);
		$upper-breakpoint: map-get($breakpoints, $upper);

		@media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) { @content; }

	} @else {
		@if (map-has-key($breakpoints, $lower) == false) {
			@warn 'Your lower breakpoint was invalid: #{$lower}.';
		}
		@if (map-has-key($breakpoints, $upper) == false) {
			@warn 'Your upper breakpoint was invalid: #{$upper}.';
		}
	}
}
@mixin respond-between-custom($lower, $upper) {
	$lower-breakpoint: $lower;
	@if map-has-key($breakpoints, $lower) {
		$lower-breakpoint: map-get($breakpoints, $lower);
	}

	$upper-breakpoint: $upper;
	@if map-has-key($breakpoints, $upper) {
		$upper-breakpoint: map-get($breakpoints, $upper);
	}

	@media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
		@content;
	}
}