.featherlight {
	background: rgba(0, 0, 0, 0.6);

	.featherlight-content {
		padding: 0 !important;
		border: none;
		background: transparent;
		box-shadow: 0 0 10px -1px #333;
		max-width: 90vw;

		@include respond-below(popup) {
			max-width: 80vw;
		}
	}

	.featherlight-close-icon {
		top: 10px;
		right: 10px;
		font-size: 30px;
		font-weight: 700;
		color: #333;
		opacity: 1;
		transition: 300ms all linear;

		&:hover {
			opacity: 0.8;
		}
	}
}
